<template>
  <main class="container pb-16 pb-lg-32 px-lg-32">
    <Table
      rowKey="id"
      :isLoading="$store.getters['users/isFetching'](params)"
      :columns="columns"
      :rows="$store.getters['users/get'](params)"
    >
      <template #top>
        <div class="p-16 border-bottom d-flex">
          <UserFilter />
          <router-link
            class="ms-auto"
            to="/admin/user/tambah"
            v-tooltip="'Tambah'"
            ><PlusCircleIcon
          /></router-link>
        </div>
      </template>

      <template #bottom>
        <div class="border-top p-16" v-if="meta && meta.last_page > 1">
          <Pagination :current="meta.current_page" :last="meta.last_page" />
        </div>
      </template>
    </Table>
  </main>
</template>

<script>
import get from 'lodash/get';
import merge from 'lodash/merge';
import userRepository from '../../../api/repositories/userRepository';
import Role from '../../../store/models/Role';

import UserFilter from '../../../components/admin/user/UserFilter.vue';
import Table from '../../../components/table/Table.vue';
import Pagination from '../../../components/table/Pagination.vue';
import RowActions from '../../../components/table/RowActions.vue';

import PencilIcon from 'bootstrap-icons/icons/pencil.svg';
import PlusCircleIcon from 'bootstrap-icons/icons/plus-circle.svg';
import TrashIcon from 'bootstrap-icons/icons/trash.svg';

export default {
  components: { UserFilter, Table, Pagination, PlusCircleIcon },

  metaInfo: { title: 'User' },

  computed: {
    params() {
      return merge({ sort: { name: 'asc' } }, this.$route.query);
    },

    filterStudentActive() {
      const roleCode = get(this.$route.query, 'filter.roles.code');
      return roleCode === Role.CODE_STUDENT;
    },

    filterTeacherActive() {
      const roleCode = get(this.$route.query, 'filter.roles.code');
      return roleCode === Role.CODE_TEACHER;
    },

    columns() {
      return [
        { key: 'name', text: 'Nama' },
        { key: 'email', text: 'Email' },
        {
          key: 'roles',
          text: 'Role',
          toHTML: (row) => row.roles.map(({ name }) => name).join(', '),
        },
        this.filterStudentActive && {
          key: 'student.grade.name',
          text: 'Kelas',
        },
        this.filterStudentActive && { key: 'student.nisn', text: 'NISN' },
        this.filterStudentActive && { key: 'student.nis', text: 'NIS' },
        this.filterTeacherActive && { key: 'teacher.nip', text: 'NIP' },
        this.filterTeacherActive && { key: 'teacher.nuptk', text: 'NUPTK' },
        {
          key: 'actions',
          component: RowActions,
          componentProps: {
            actions: (row) =>
              [
                {
                  icon: PencilIcon,
                  text: 'Ubah',
                  onClick: ({ row }) => this.$router.push(row.editRoutePath),
                },
                row.id !== this.$auth.user.id && {
                  icon: TrashIcon,
                  text: 'Hapus',
                  attrs: { class: 'text-danger' },
                  onClick: this.handleRemoveClick,
                },
              ].filter(Boolean),
          },
          props: { class: 'text-end' },
        },
      ].filter(Boolean);
    },

    meta() {
      return this.$store.getters['users/getMeta'](this.params);
    },
  },

  methods: {
    handleRemoveClick({ row }) {
      this.$confirmation.confirm(
        `Anda yakin mau menghapus <b>${row.name}</b>?`,
        {
          onOK: async () => {
            try {
              await userRepository.destroy(row.id);
              this.$store.dispatch('users/fetchAndInvalidate', this.params);
              this.$alert.success('User berhasil dihapus');
            } catch (error) {
              this.$alert.requestError(error);
            }
          },
        }
      );
    },

    fetch() {
      this.$store.dispatch('users/maybeFetch', this.params);
    },
  },

  beforeMount() {
    this.fetch();
  },

  watch: {
    '$route.query': {
      handler() {
        this.fetch();
      },
      deep: true,
    },
  },
};
</script>
