<template>
  <div class="wrapper d-flex align-items-center">
    <VDropdown
      class="d-inline-flex align-items-center me-16"
      @apply-show="focusToField"
    >
      <!-- This will be the popover reference (for the events and position) -->
      <button
        class="btn-plain d-inline-flex align-items-center"
        v-tooltip="'Cari'"
      >
        <SearchIcon />
      </button>

      <!-- This will be the content of the popover -->
      <template #popper>
        <div class="popper-content p-16">
          <InputField
            desc="Cari nama atau email"
            label="Cari"
            v-model="input.search"
          />
          <RoleSelectField
            class="mt-16"
            label="Role"
            v-model="input.filter.role_code"
          />
          <GradeSelectField
            class="mt-16"
            label="Kelas"
            v-if="isStudent"
            v-model="input.filter.student_grade_id"
          />
        </div>
      </template>
    </VDropdown>

    <ActiveFilters :activeFilters="activeFilters" @remove="removeFilter" />
  </div>
</template>

<script>
import Grade from '../../../store/models/Grade';
import Role from '../../../store/models/Role';

import InputField from '../../form/InputField.vue';
import GradeSelectField from '../../grade/GradeSelectField.vue';
import RoleSelectField from '../../role/RoleSelectField.vue';
import ActiveFilters from '../../table/ActiveFilters.vue';
import BaseFilter from '../../table/BaseFilter.vue';
import VDropdown from '../../v-tooltip/VDropdown.vue';

import SearchIcon from 'bootstrap-icons/icons/search.svg';

export default {
  extends: BaseFilter,

  components: {
    InputField,
    GradeSelectField,
    RoleSelectField,
    ActiveFilters,
    VDropdown,
    SearchIcon,
  },

  computed: {
    isStudent() {
      return this.input.filter.role_code === Role.CODE_STUDENT;
    },
  },

  methods: {
    /**
     * @see BaseFilter.activeFilters
     */
    getActiveFilterRoleCode(value) {
      const role = Role.find(value);

      if (role) {
        return {
          label: 'Role',
          key: 'role_code',
          value: role.name,
        };
      }
    },

    /**
     * @see BaseFilter.activeFilters
     */
    getActiveFilterStudentGradeId(value) {
      const grade = Grade.find(value);

      if (grade) {
        return {
          label: 'Kelas',
          key: 'student_grade_id',
          value: grade.name,
        };
      }
    },

    /**
     * @see BaseFilter.parseFilter
     */
    parseFilterRoles(result, value) {
      result.role_code = value && value.code;
    },

    /**
     * @see BaseFilter.parseFilter
     */
    parseFilterStudent(result, value) {
      result.student_grade_id = value && value.grade_id;
    },

    /**
     * @see BaseFilter.prepareFilter
     */
    prepareFilterRoleCode(result, value) {
      result.roles = { ...result.roles, code: value };
    },

    /**
     * @see BaseFilter.prepareFilter
     */
    prepareFilterStudentGradeId(result, value) {
      result.student = { ...result.student, grade_id: value };
    },
  },
};
</script>
